import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/AuthLogin'

import { authGuard } from "../auth/authGuard";

const fb = require('../firebaseConfig.js')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  },
  {
    path: '/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "about" */ '../views/ClientFiles.vue'),
    beforeEnter: authGuard
  },
  {
      path: '/login',
      component: Login
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Home,
    params: { demo: true } ,
    beforeEnter: authGuard
  },
  {
    path: '/n/:id',
    name: 'ViewNamelist',
    component: () => import(/* webpackChunkName: "about" */ '../views/NamelistSummary.vue'),
    beforeEnter: authGuard

  },

  {
    path: '/n/:namelist_name/subscribers',
    name: 'Subscribers',
    component: () => import(/* webpackChunkName: "about" */ '../views/Subscribers.vue'),
    beforeEnter: authGuard
  },

  {
    path: '/nsid/:nsid',
    name: 'ViewSubscriber',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubscriberLanding.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/s/:id',
    name: 'ViewSubscriberById',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubscriberLanding.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/usecases',
    name: 'AllUsecases',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usecases.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/usecases/:client',
    name: 'Usecases',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usecases.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/internal/linkedin',
    name: 'Internal.Linked',
    component: () => import(/* webpackChunkName: "about" */ '../views/internal/InternalLinkedin.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/internal/queues',
    name: 'Internal.Queues',
    component: () => import(/* webpackChunkName: "about" */ '../views/internal/Queues.vue'),
    beforeEnter: authGuard
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// fb.auth.onAuthStateChanged(function (user) {
//   console.log(router.currentRoute.path)
//   if (!user) {
//     if (router.currentRoute.path !== '/login') {
//       router.push('/login')
//     }
//   } else if (router.currentRoute.path === '/login') {
//     router.push('/')
//   }
// })



/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})



export default router
